import { Box } from 'grommet';

export const DraftsIcon = (props: { size?: number }) => {
  const size = props.size || 18;

  return (
    <Box>
      <svg
        width={size + 1}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.4812 2.26884C21.456 1.24372 19.794 1.24372 18.7688 2.26884L17.6117 3.42599L21.324 7.1383L22.4812 5.98116C23.5063 4.95603 23.5063 3.29397 22.4812 2.26884Z"
          fill="#111827"
        />
        <path
          d="M20.2633 8.19896L16.551 4.48665L8.15019 12.8875C7.53341 13.5043 7.08002 14.265 6.831 15.101L6.03122 17.7859C5.9526 18.0498 6.02494 18.3356 6.21967 18.5303C6.4144 18.7251 6.70019 18.7974 6.96412 18.7188L9.64901 17.919C10.485 17.67 11.2457 17.2166 11.8625 16.5998L20.2633 8.19896Z"
          fill="#111827"
        />
        <path
          d="M6 5.24999C4.34315 5.24999 3 6.59314 3 8.24999V18.75C3 20.4069 4.34315 21.75 6 21.75H16.5C18.1569 21.75 19.5 20.4069 19.5 18.75V13.5C19.5 13.0858 19.1642 12.75 18.75 12.75C18.3358 12.75 18 13.0858 18 13.5V18.75C18 19.5784 17.3284 20.25 16.5 20.25H6C5.17157 20.25 4.5 19.5784 4.5 18.75V8.24999C4.5 7.42156 5.17157 6.74999 6 6.74999H11.25C11.6642 6.74999 12 6.41421 12 5.99999C12 5.58578 11.6642 5.24999 11.25 5.24999H6Z"
          fill="#111827"
        />
      </svg>
    </Box>
  );
};
