import { Box } from 'grommet';

export const SupportIcon = (props: { size?: number }) => {
  const size = props.size || 18;
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M17.0264 3.81846C17.6531 4.2042 18.2451 4.6686 18.7881 5.21168C19.3312 5.75475 19.7956 6.3467 20.1813 6.97337M17.0264 3.81846L13.3478 8.23284M17.0264 3.81846C13.9536 1.92705 10.0462 1.92705 6.97337 3.81846M20.1813 6.97337L15.767 10.652M20.1813 6.97337C22.0728 10.0462 22.0728 13.9536 20.1813 17.0264M15.767 10.652C15.574 10.1115 15.2611 9.60425 14.8283 9.17148C14.3956 8.7387 13.8883 8.42582 13.3478 8.23284M15.767 10.652C16.0775 11.5219 16.0775 12.4779 15.767 13.3478M13.3478 8.23284C12.4779 7.92226 11.5219 7.92226 10.652 8.23284M15.767 13.3478C15.574 13.8883 15.2611 14.3956 14.8283 14.8283C14.3956 15.2611 13.8883 15.574 13.3478 15.767M15.767 13.3478L20.1813 17.0264M20.1813 17.0264C19.7956 17.6531 19.3312 18.2451 18.7881 18.7881C18.2451 19.3312 17.6531 19.7956 17.0264 20.1813M17.0264 20.1813L13.3478 15.767M17.0264 20.1813C13.9536 22.0728 10.0462 22.0728 6.97337 20.1813M13.3478 15.767C12.4779 16.0776 11.5219 16.0776 10.652 15.767M10.652 15.767C10.1115 15.574 9.60425 15.2611 9.17148 14.8283C8.7387 14.3956 8.42582 13.8883 8.23284 13.3478M10.652 15.767L6.97337 20.1813M6.97337 20.1813C6.3467 19.7956 5.75475 19.3312 5.21168 18.7881C4.6686 18.2451 4.2042 17.6531 3.81846 17.0264M3.81846 17.0264L8.23284 13.3478M3.81846 17.0264C1.92705 13.9536 1.92705 10.0462 3.81846 6.97337M8.23284 13.3478C7.92225 12.4779 7.92226 11.5219 8.23284 10.652M8.23284 10.652C8.42582 10.1115 8.7387 9.60425 9.17148 9.17148C9.60425 8.7387 10.1115 8.42582 10.652 8.23284M8.23284 10.652L3.81846 6.97337M10.652 8.23284L6.97337 3.81846M6.97337 3.81846C6.3467 4.2042 5.75475 4.6686 5.21168 5.21168C4.6686 5.75475 4.2042 6.3467 3.81846 6.97337"
          stroke="#1F2937"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
