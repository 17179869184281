import { Box } from 'grommet';

export const SettignsIcon = (props: { size?: number }) => {
  const size = props.size || 18;

  return (
    <Box>
      <svg
        width={size + 1}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.84377 3.94005C9.93418 3.39759 10.4035 3 10.9535 3H13.5474C14.0974 3 14.5667 3.39759 14.6571 3.94005L14.8706 5.2211C14.933 5.59514 15.1829 5.90671 15.5147 6.09036C15.5889 6.13142 15.6622 6.17383 15.7346 6.21757C16.0597 6.41384 16.455 6.47486 16.8106 6.34166L18.0274 5.88578C18.5424 5.69284 19.1214 5.90051 19.3964 6.37677L20.6934 8.62321C20.9683 9.09948 20.8587 9.70473 20.4341 10.0543L19.4298 10.8811C19.1373 11.1219 18.9923 11.4937 18.9993 11.8725C19.0001 11.9149 19.0004 11.9574 19.0004 12C19.0004 12.0426 19.0001 12.0851 18.9993 12.1275C18.9923 12.5063 19.1373 12.8781 19.4298 13.1189L20.4341 13.9457C20.8587 14.2953 20.9683 14.9005 20.6934 15.3768L19.3964 17.6232C19.1214 18.0995 18.5424 18.3071 18.0274 18.1142L16.8106 17.6583C16.455 17.5251 16.0597 17.5862 15.7347 17.7824C15.6622 17.8262 15.5889 17.8686 15.5147 17.9096C15.1829 18.0933 14.933 18.4049 14.8706 18.7789L14.6571 20.0599C14.5667 20.6024 14.0974 21 13.5474 21H10.9535C10.4035 21 9.93418 20.6024 9.84377 20.0599L9.63026 18.7789C9.56792 18.4049 9.31795 18.0933 8.98618 17.9096C8.91201 17.8686 8.83869 17.8262 8.76626 17.7824C8.44122 17.5862 8.04589 17.5251 7.69033 17.6583L6.47346 18.1142C5.95847 18.3072 5.37948 18.0995 5.1045 17.6232L3.80752 15.3768C3.53255 14.9005 3.6422 14.2953 4.06678 13.9457L5.07114 13.1189C5.36364 12.8781 5.50864 12.5063 5.50163 12.1275C5.50084 12.0851 5.50045 12.0426 5.50045 12C5.50045 11.9574 5.50084 11.9149 5.50163 11.8725C5.50864 11.4937 5.36364 11.1219 5.07114 10.8811L4.06678 10.0543C3.6422 9.70475 3.53255 9.09949 3.80752 8.62323L5.1045 6.37679C5.37948 5.90052 5.95847 5.69286 6.47346 5.88579L7.69031 6.34167C8.04588 6.47487 8.44121 6.41385 8.76624 6.21758C8.83868 6.17384 8.912 6.13142 8.98618 6.09036C9.31795 5.90671 9.56792 5.59514 9.63026 5.2211L9.84377 3.94005Z"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2502 11.9999C15.2502 13.6568 13.9071 14.9999 12.2502 14.9999C10.5934 14.9999 9.25022 13.6568 9.25022 11.9999C9.25022 10.3431 10.5934 8.99992 12.2502 8.99992C13.9071 8.99992 15.2502 10.3431 15.2502 11.9999Z"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
